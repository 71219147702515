const InputPassword = ({ register, errors }) => {
  return (
    <>
      <label className="sub-title" htmlFor="password">
        パスワード
      </label>
      <input
        type="password"
        id="password"
        placeholder="パスワード"
        {...register("password", {
          required: "パスワードを入力してください。",
          minLength: {
            value: 6,
            message: "パスワードは6文字以上で入力してください。",
          },
        })}
      />
      {errors.password && (
        <div className="error-msg">{errors.password.message}</div>
      )}
    </>
  );
};

export default InputPassword;

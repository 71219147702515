import axios from "axios";

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(
      error.response.data.msg || "時間をおいてお試しください。"
    );
  }
);

const ENDPOINT_URL = "/api/users";

const userApi = {
  async login(address, password) {
    try {
      const result = await axios.post(ENDPOINT_URL, { address, password });
      return result.data;
    } catch (error) {
      console.error("API call error:", error);
      throw error;
    }
  },
};

export default userApi;

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useBooks, useDispatchBooks } from "../contexts/BookContext";
import bookApi from "../api/book";
import Button from "../components/Button";
import {
  InputBookTitle,
  InputBookAuthor,
  InputBookComment,
  InputBookRating,
  InputBookAmazon,
  InputBookImage,
} from "../components/forms/books";
import { useUser } from "../contexts/UserContext";

const EditModal = ({ book, setBook, toggleEditModal }) => {
  const { isEditable } = useUser();
  const dispatch = useDispatchBooks();
  const [fileName, setFileName] = useState("No Image...");

  const [editedBook, setEditedBook] = useState({ ...book });
  const clickCancel = () => toggleEditModal();

  const handleChangeRating = (rate) =>
    setEditedBook({ ...editedBook, rating: rate });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    criteriaMode: "firstError", // デフォルト: "firstError"。全て出力は、"all"。.
    mode: "onSubmit", // デフォルト: onSubmit。onChange、onBlur、onTouched
    reValidateMode: "onSubmit", // 2回目以降のバリデーション。デフォルト: onChange。
    defaultValues: {
      // 初回レンダリング時のフォームのデフォルト値
      title: editedBook.title,
      author: editedBook.author,
      comment: editedBook.comment,
      amazon: editedBook.amazon,
    },
  });
  useEffect(() => {
    console.log(isEditable);
  }, [isEditable]);

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setFileName(e.target.files[0].name);
      setEditedBook({ ...editedBook, image: e.target.files[0] });
    } else {
      setFileName("No Image...");
      setEditedBook({ ...editedBook, image: null });
    }
  };

  const [error, setError] = useState("");
  const onSubmit = async (inputs) => {
    const formData = new FormData();
    formData.append("_id", editedBook._id);
    formData.append("rating", editedBook.rating);
    formData.append("title", inputs.title);
    formData.append("author", inputs.author);
    formData.append("comment", inputs.comment);
    formData.append("amazon", inputs.amazon);

    if (editedBook.image) {
      formData.append("image", editedBook.image);
    }

    try {
      const _editedBook = await bookApi.patch(formData, editedBook._id);
      dispatch({ type: "book/update", book: _editedBook });
      reset();
      setBook(_editedBook);
      toggleEditModal();
    } catch (e) {
      console.log("error occured!", e);
      setError(e.message);
    }
  };

  return (
    <div className="modal-container">
      <form className="modal" onSubmit={handleSubmit(onSubmit)}>
        <h3 className="page-title">
          [{editedBook.title}]<span>を編集</span>
        </h3>

        <InputBookTitle register={register} errors={errors} />
        <InputBookAuthor register={register} errors={errors} />
        <InputBookComment register={register} errors={errors} />
        <InputBookAmazon register={register} errors={errors} />
        <InputBookImage
          register={register}
          errors={errors}
          onChange={handleFileChange}
          fileName={fileName}
          mode={"edit"}
        />
        <InputBookRating
          rating={editedBook.rating}
          onChange={handleChangeRating}
        />

        <div className="error-msg text-center">{error}</div>

        <div className="footer">
          <Button className="gray mr-16" onClick={clickCancel}>
            キャンセル
          </Button>
          <Button className="blue" disabled={!isEditable}>
            確定する
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditModal;

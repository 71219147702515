import { BrowserRouter, Routes, Route } from "react-router-dom";

import { BookProvider } from "./contexts/BookContext";
import { UserProvider } from "./contexts/UserContext";
import Header from "./components/Header";
import Home from "./pages/Home";
import Books from "./pages/Books";
import Book from "./pages/Book";
import Page404 from "./pages/Page404";
import SignIn from "./pages/SiginIn";
import GoogleAnalistics from "./components/analistics/GoogleAnalistics";

const App = () => {
  return (
    <BrowserRouter>
      <GoogleAnalistics>
        <BookProvider>
          <UserProvider>
            <Header />
            <div className="container">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/books" element={<Books />} />
                <Route path="/books/:id" element={<Book />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/*" element={<Page404 />} />
              </Routes>
            </div>
          </UserProvider>
        </BookProvider>
      </GoogleAnalistics>
    </BrowserRouter>
  );
};

export default App;

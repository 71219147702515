const InputBookAmazon = ({ register, errors }) => {
  return (
    <>
      <label className="sub-title" htmlFor="book-amazon">
        AmazonのURL
      </label>
      <input
        type="text"
        id="book-amazon"
        placeholder="AmazonのURL"
        {...register("amazon", {
          required: "URLを入力してください。",
        })}
      />
      {errors.amazon && (
        <div className="error-msg">{errors.amazon.message}</div>
      )}
    </>
  );
};

export default InputBookAmazon;

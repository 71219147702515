import { Link, useNavigate } from "react-router-dom";
import { useDispatchUser, useUser } from "../contexts/UserContext";

const Header = () => {
  const { user } = useUser();
  const dispatch = useDispatchUser();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("loginUsersOnBookShelf");
    dispatch({ type: "user/update", user: { address: "", password: "" } });
    navigate("/signin");
  };
  return (
    <>
      <header className="header">
        <div className="header__inner">
          <Link to="/">
            <img src="/images/logo_blue_2.png" alt="BookShelf" />
          </Link>

          <nav className="header__nav">
            <ul className="header__ul">
              <li className="header__list">
                <Link to="/">新規投稿</Link>
              </li>
              <li className="header__list">
                <Link to="/books">一覧ページ</Link>
              </li>
              {user.address && user.password ? (
                <li className="header__list">
                  <button onClick={handleLogout}>ログアウト</button>
                </li>
              ) : (
                <li className="header__list">
                  <Link to="/signin">ログイン</Link>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;

import { Link } from "react-router-dom";
import Rating from "react-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FaExternalLinkAlt } from "react-icons/fa";
import { COLORS, EXCERPT } from "../utils/config";

const Card = ({ book }) => {
  // 概要(20文字)...20文字を超えた場合は20文字にカットした概要文とする。
  // 20文字を越えなかった場合はそのまま
  // substring(開始, 終了)...開始から終了までの文字列を返す。
  const author =
    book.author.length > EXCERPT.author
      ? book.author.substring(0, EXCERPT.author) + "..."
      : book.author;

  // コメント(48文字)
  const comment =
    book.comment.length > EXCERPT.comment
      ? book.comment.substring(0, EXCERPT.comment) + "..."
      : book.comment;

  // 改行を<br>タグに置き換える
  const formatComment = (text) => {
    return text.split("\n").map((str, index) => (
      <span key={index}>
        {str}
        <br />
      </span>
    ));
  };
  // 画像データに適切なヘッダを追加
  const imageUrl =
    book.image && book.imageMimeType
      ? `data:${book.imageMimeType};base64,${book.image}`
      : null;
  return (
    <div className="card">
      <Link to={`/books/${book._id}`}>
        <h3 className="book-title">{book.title}</h3>

        <div className="card__rating">
          <Rating
            emptySymbol={
              <FontAwesomeIcon icon={faStar} color={COLORS.star.empty} />
            }
            fullSymbol={
              <FontAwesomeIcon icon={faStar} color={COLORS.star.full} />
            }
            fractions={1}
            initialRating={book.rating}
            readonly={true}
          />
        </div>
        {imageUrl && (
          <img className="book-img" src={imageUrl} alt={book.title} />
        )}
        <div>
          <div className="sub-title">著者</div>
          <p className="text">{author}</p>
        </div>

        <div>
          <div className="sub-title">本の感想</div>
          <p className="text">{formatComment(comment)}</p>
        </div>
      </Link>
      <div className="link-title">
        <a href={book.amazon} target="_blank" rel="noopener noreferrer">
          {"Amazonで見る"}
        </a>
        <FaExternalLinkAlt />
      </div>
    </div>
  );
};

export default Card;

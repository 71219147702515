const Button = ({ onClick, children, style, className, disabled = false }) => {
  const disabledString = disabled ? "disabled" : "";
  return (
    <button
      className={`btn ${className} ${disabledString}`}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;

import { useState } from "react";
import { IoAttachSharp } from "react-icons/io5";
import { MdOutlineAttachment } from "react-icons/md";
const InputBookImage = ({ register, errors, onChange, fileName, mode }) => {
  return (
    <>
      <div className="image-title">
        <label htmlFor="book-image">
          画像
          <MdOutlineAttachment />
        </label>
      </div>
      {mode === "post" ? (
        <input
          style={{ display: "none" }}
          type="file"
          id="book-image"
          accept=".png, .jpg, .jpeg"
          placeholder="No Image..."
          {...register("image", {
            required: "画像を添付してください。",
            validate: {
              maxSize: (files) =>
                files[0]?.size <= 5 * 1024 * 1024 ||
                "5MB以下の画像を添付してください。",
            },
            onChange: onChange,
          })}
        />
      ) : (
        <input
          style={{ display: "none" }}
          type="file"
          id="book-image"
          accept=".png, .jpg, .jpeg"
          placeholder="No Image..."
          {...register("image", {
            onChange: onChange,
          })}
        />
      )}

      <input type="text" disabled placeholder="No Image..." value={fileName} />
      {errors.image && <div className="error-msg">{errors.image.message}</div>}
    </>
  );
};

export default InputBookImage;

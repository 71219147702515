const InputAddress = ({ register, errors }) => {
  return (
    <>
      <label className="sub-title" htmlFor="address">
        メールアドレス
      </label>
      <input
        type="text"
        id="address"
        placeholder="メールアドレス"
        {...register("address", {
          required: "メールアドレスを入力してください。",
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
            message: "有効なメールアドレスを入力してください。",
          },
        })}
      />
      {errors.address && (
        <div className="error-msg">{errors.address.message}</div>
      )}
    </>
  );
};

export default InputAddress;

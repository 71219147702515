import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import userApi from "../api/user";
import {
  InputAddress,
  InputEmail,
  InputPassword,
} from "../components/forms/users";

import Button from "../components/Button";
import { useDispatchUser } from "../contexts/UserContext";

const SignIn = () => {
  const dispatch = useDispatchUser();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    // デフォルト: "firstError"。全て出力は、"all"。
    criteriaMode: "firstError",
    // デフォルト: onSubmit。onChange、onBlur、onTouched
    mode: "onSubmit",
    // 2回目以降のバリデーション。デフォルト: onChange。
    reValidateMode: "onSubmit",
  });

  const [error, setError] = useState("");
  const onSubmit = (formInputs) => {
    const { address, password } = formInputs;
    userApi
      .login(address, password)
      .then((result) => {
        if (result) {
          dispatch({ type: "user/update", user: { address, password } });
          reset();
          navigate("/books");
        }
      })
      .catch((e) => {
        console.log("error occurred!", e);
        setError("ログイン失敗");
      });
  };

  return (
    <div className="small-container">
      <h2 className="page-title">ログイン画面</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputAddress register={register} errors={errors} />
        <InputPassword register={register} errors={errors} />
        <div className="error-msg text-center">{error}</div>

        <div className="footer">
          <Button className="blue">ログイン</Button>
        </div>
      </form>
    </div>
  );
};

export default SignIn;

import {
  useEffect,
  useContext,
  createContext,
  useReducer,
  useState,
} from "react";

// UserContextとそのディスパッチ用コンテキストを作成
const UserContext = createContext();
const UserDispatchContext = createContext();

// 他のファイルからstateを参照できるようにエクスポート
const useUser = () => useContext(UserContext);
const useDispatchUser = () => useContext(UserDispatchContext);

// reducerを定義
const reducer = (user, action) => {
  switch (action.type) {
    case "user/init":
      return action.user;

    case "user/update":
      const updatedUser = { ...user, ...action.user };
      localStorage.setItem(
        "loginUsersOnBookShelf",
        JSON.stringify(updatedUser)
      );
      return updatedUser;

    default:
      return user;
  }
};

// 初期化関数を定義
const initializeUser = () => {
  const storedUser = localStorage.getItem("loginUsersOnBookShelf");
  if (storedUser) {
    return JSON.parse(storedUser);
  } else {
    return { address: "", password: "" };
  }
};

const UserProvider = ({ children }) => {
  const [user, dispatch] = useReducer(reducer, {}, initializeUser);
  const [isEditable, setIsEditable] = useState(true);

  // 初期化
  useEffect(() => {
    dispatch({ type: "user/init", user: initializeUser() });
  }, []);

  // isEditableの状態を更新
  useEffect(() => {
    if (user.address && user.password) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, isEditable }}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};

export { useUser, useDispatchUser, UserProvider };

const InputBookAuthor = ({ register, errors }) => {
  return (
    <>
      <label className="sub-title" htmlFor="book-author">
        著者
      </label>
      <input
        type="text"
        id="book-author"
        placeholder="著者"
        {...register("author", {
          required: "著者を入力してください。",
        })}
      />
      {errors.author && (
        <div className="error-msg">{errors.author.message}</div>
      )}
    </>
  );
};

export default InputBookAuthor;

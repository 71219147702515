import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
// Google Analyticsの初期化
ReactGA.initialize("G-0S3CQHTNGJ");
const logPageView = (location) => {
  ReactGA.set({ page: location.pathname + location.search });
  ReactGA.pageview(location.pathname + location.search);
};
const GoogleAnalistics = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location);
  }, [location]);
  useEffect(() => {}, []);

  return <>{children}</>;
};

export default GoogleAnalistics;

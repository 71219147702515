import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import bookApi from "../api/book";
import { useDispatchBooks } from "../contexts/BookContext";
import {
  InputBookTitle,
  InputBookAuthor,
  InputBookComment,
  InputBookRating,
  InputBookAmazon,
  InputBookImage,
} from "../components/forms/books";

import Button from "../components/Button";
import { useUser } from "../contexts/UserContext";

const Home = () => {
  const dispatch = useDispatchBooks();
  const { isEditable } = useUser();

  const navigate = useNavigate();

  const [rating, setRating] = useState(1);
  const handleChangeRating = (rating) => setRating(rating);
  const [fileName, setFileName] = useState("No Image...");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    // デフォルト: "firstError"。全て出力は、"all"。
    criteriaMode: "firstError",
    // デフォルト: onSubmit。onChange、onBlur、onTouched
    mode: "onSubmit",
    // 2回目以降のバリデーション。デフォルト: onChange。
    reValidateMode: "onSubmit",
  });
  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setFileName(e.target.files[0].name);
    } else {
      setFileName("No Image...");
    }
  };

  const [error, setError] = useState("");
  // const onSubmit = (formInputs) => {
  //   formInputs.rating = rating;

  //   bookApi
  //     .post(formInputs)
  //     .then((_newBook) => {
  //       dispatch({ type: "book/add", book: _newBook });
  //       reset();
  //       navigate("/books");
  //     })
  //     .catch((e) => {
  //       console.log("error occured!", e);
  //       setError(e);
  //     });
  // };
  const onSubmit = async (formInputs) => {
    formInputs.rating = rating;
    const formData = new FormData();

    for (const key in formInputs) {
      if (key === "image" && formInputs[key][0]) {
        formData.append(key, formInputs[key][0]);
      } else {
        formData.append(key, formInputs[key]);
      }
    }

    try {
      const _newBook = await bookApi.post(formData);
      dispatch({ type: "book/add", book: _newBook });
      reset();
      navigate("/books");
    } catch (e) {
      console.log("error occured!", e);
      setError(e);
    }
  };
  return (
    <div className="small-container">
      <h2 className="page-title">新規投稿フォーム</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputBookTitle register={register} errors={errors} />
        <InputBookImage
          register={register}
          errors={errors}
          onChange={handleFileChange}
          fileName={fileName}
          mode={"post"}
        />
        <InputBookAuthor register={register} errors={errors} />
        <InputBookComment register={register} errors={errors} />
        <InputBookAmazon register={register} errors={errors} />
        <InputBookRating rating={rating} onChange={handleChangeRating} />
        <div className="error-msg text-center">{error}</div>

        <div className="footer">
          <Button className="blue" disabled={!isEditable}>
            追加する
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Home;

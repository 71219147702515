const COLORS = {
  main: {
    bg: {},
    primary: {},
    secondary: {},
    accent: {},
  },
  star: {
    full: "#ffd233",
    empty: "#e4dccb",
  },
};

const EXCERPT = {
  author: 24,
  comment: 48,
};

export { EXCERPT, COLORS };
